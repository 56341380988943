import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { PurchaseReturnsFacade } from '../facades/purchase-returns.facade';

export const purchaseReturnsCleanup: CanDeactivateFn<unknown> = () => {
    const purchaseReturnsFacade = inject(PurchaseReturnsFacade);

    purchaseReturnsFacade.clear();
    return true;
};
