import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap } from 'rxjs';
import { purchaseReturnsActions } from '../actions/purchase-returns.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class PurchaseReturnsEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getPurchaseReturns$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(purchaseReturnsActions.getPurchaseReturns),
            switchMap(({ value }) => {
                return this.httpService.getPurchaseReturns(value).pipe(
                    map(({ items, totalItems }) => purchaseReturnsActions.getPurchaseReturnsSuccess({ items, totalItems, value })),
                    catchError(() => of(purchaseReturnsActions.getPurchaseReturnsError())),
                );
            }),
        );
    });
}
