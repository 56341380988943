import { createReducer, on } from '@ngrx/store';
import { getKey } from '@app/store/utils';
import { PurchaseReturn } from '@app/store/models';
import { purchaseReturnsActions } from '../actions/purchase-returns.actions';

export interface Result {
    items: PurchaseReturn[] | null;
    totalItems: number | null;
}

export interface PurchaseReturnsState {
    loaded: null | boolean;
    loading: boolean;
    results: Map<string, Result>;
    currentResult: null | Result;
}

export const initialState: PurchaseReturnsState = {
    loaded: null,
    loading: false,
    results: new Map(),
    currentResult: null,
};

export const purchaseReturnsReducer = createReducer(
    initialState,
    on(purchaseReturnsActions.getPurchaseReturns, (state, { value }): PurchaseReturnsState => {
        const results = new Map(state.results);
        const key = getKey(value);
        const result = results.get(key);

        if (result) {
            return { ...state, loading: false, loaded: true, currentResult: result };
        }

        return { ...state, loading: true };
    }),
    on(purchaseReturnsActions.getPurchaseReturnsSuccess, (state, { value, totalItems, items }): PurchaseReturnsState => {
        const results = new Map(state.results);
        const key = getKey(value);
        const result: Result = { items, totalItems };

        results.set(key, result);

        return { ...state, loaded: true, loading: false, results, currentResult: result };
    }),
    on(purchaseReturnsActions.getPurchaseReturnsError, (state): PurchaseReturnsState => ({ ...state, loaded: false, loading: false })),

    on(purchaseReturnsActions.clear, (): PurchaseReturnsState => ({ ...initialState })),
);
