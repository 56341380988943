import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PurchaseReturn } from '@app/store/models';
import { PurchaseReturnValue } from '../models';

export const purchaseReturnsActions = createActionGroup({
    source: 'Purchase Returns',
    events: {
        'Get Purchase Returns': props<{ value: PurchaseReturnValue }>(),
        'Get Purchase Returns Success': props<{ value: PurchaseReturnValue; items: PurchaseReturn[]; totalItems: number }>(),
        'Get Purchase Returns Error': emptyProps(),

        Clear: emptyProps(),
    },
});
