import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PurchaseReturnsState } from '../reducers/purchase-returns.reducer';
import { PURCHASE_RETURNS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<PurchaseReturnsState>(PURCHASE_RETURNS_FEATURE_KEY);
const selectCurrentResult = createSelector(selectState, (state: PurchaseReturnsState) => state.currentResult);

export const selectLoaded = createSelector(selectState, (state: PurchaseReturnsState) => state.loaded);
export const selectLoading = createSelector(selectState, (state: PurchaseReturnsState) => state.loading);
export const selectItems = createSelector(selectCurrentResult, (result) => result?.items ?? []);
export const selectTotalItems = createSelector(selectCurrentResult, (result) => result?.totalItems ?? null);
