import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PURCHASE_RETURNS_FEATURE_KEY } from './keys';
import { purchaseReturnsReducer } from './reducers/purchase-returns.reducer';
import { PurchaseReturnsEffects } from './effects/purchase-returns.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PURCHASE_RETURNS_FEATURE_KEY, purchaseReturnsReducer), EffectsModule.forFeature([PurchaseReturnsEffects])],
})
export class PurchaseReturnsStoreModule {}
