import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectItems, selectLoaded, selectLoading, selectTotalItems } from '../selectors/purchase-returns.selector';
import { purchaseReturnsActions } from '../actions/purchase-returns.actions';
import { PurchaseReturnValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class PurchaseReturnsFacade {
    private readonly store = inject(Store);

    totalItems$ = this.store.select(selectTotalItems);
    items$ = this.store.select(selectItems);
    loading$ = this.store.select(selectLoading);
    loaded$ = this.store.select(selectLoaded);

    getPurchaseReturns(value: PurchaseReturnValue): void {
        this.store.dispatch(purchaseReturnsActions.getPurchaseReturns({ value }));
    }

    clear(): void {
        this.store.dispatch(purchaseReturnsActions.clear());
    }
}
