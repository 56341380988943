import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { ApiListingResponse } from '@app/shared/models';
import { PurchaseReturn, PurchaseReturnDto } from '@app/store/models';
import { PurchaseReturnValue, PurchaseReturnValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getPurchaseReturns(value: PurchaseReturnValue) {
        const { params } = new PurchaseReturnValueDto(value);

        return this.httpClient
            .addUserSwitch()
            .get<ApiListingResponse<PurchaseReturnDto[]>>('api/v1/purchase-returns', { params })
            .pipe(
                map((response) => ({
                    items: response.data.map((productDto) => new PurchaseReturn(productDto)),
                    totalItems: response.pagination.totalItems,
                })),
            );
    }
}
